@import "../../styles/global.scss";

.background {
  grid-column: full-start / full-end;
  grid-row: 4 / 5;

  width: 100%;
  background: radial-gradient(#270e2f, #6e5e74 130%);
  clip-path: polygon(0% 0%, 100% 7%, 100% 100%, 0% 93%);

  @include respond(tab-port) {
    clip-path: none;
  }
}

.container {
  grid-column: center-start / center-end;
  grid-row: 4 / 5;
  z-index: 2;

  display: grid;
  row-gap: 8rem;

  margin-top: 10%;
  margin-bottom: 15%;

  @include respond(tab-port) {
    row-gap: 8rem;
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, min-content);
    justify-items: center;
    align-items: center;

    @include respond(tab-port) {
      row-gap: 4rem;
    }

    & img {
      height: 385px;
      width: 70%;
      border-radius: 10px;
      box-shadow: 0 1rem 2rem rgba($color-dark, 0.1);
      object-fit: cover;

      @include respond(tab-port) {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        height: 330px;
      }

      @include respond(phone) {
        width: 100%;
        height: 300px;
      }
    }

    & div {
      display: grid;
      row-gap: 1.5rem;

      @include respond(tab-port) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 80%;
        text-align: center;
      }

      @include respond(phone) {
        width: 100%;
      }
    }

    & h3 {
      color: $color-secondary;
      font-size: 3rem;
    }

    & p {
      color: $color-white;
      font-size: 1.8rem;
      text-align: justify;
    }
  }
}

.container-2 {
  grid-column: center-start / center-end;
  grid-row: 5 / 6;
  z-index: 2;

  display: grid;
  row-gap: 8rem;

  margin-top: 10%;
  margin-bottom: 15%;

  & h3 {
    color: $color-primary;
  }

  & p {
    color: $color-dark;
    font-size: 1.8rem;
    text-align: justify;
  }
}
