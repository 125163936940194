$color-primary-layer: #1a1039;
$color-primary-light: rgba(#8158ff, 0.15);
$color-primary: rgba(#1a1039, 0.8);

$color-secondary: #ccfbf8;

$color-layer: #1a1039;

$color-white: #ffffff;
$color-dark: #000000;
