@import "../../styles/global.scss";

.container {
  grid-column: center-start / center-end;
  grid-row: 2 / 3;
  padding-top: 10rem;
  padding-bottom: 10rem;

  display: grid;
  grid-gap: 5rem;
  justify-items: center;

  & h2 {
    @include respond(tab-port) {
      text-align: center;
    }
  }

  & span {
    color: $color-primary;
  }

  &--cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    column-gap: 7rem;
    row-gap: 4rem;
  }

  &-card {
    background-color: $color-primary-light;
    border-radius: 10px;

    &__top {
      display: grid;

      .layer {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        background-color: rgba($color-dark, $alpha: 0.15);
        height: 100%;
        width: 100%;

        z-index: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      & img {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 100%;
        height: 330px;
        object-fit: cover;

        @include respond(tab-port) {
          height: 280px;
        }
      }

      & h3 {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        align-self: flex-end;
        z-index: 1;
        padding-left: 2rem;
        padding-bottom: 1.5rem;
      }
    }

    & p {
      padding: 2rem 2rem;
      font-size: 1.8rem;
      text-align: justify;
    }
  }
}
