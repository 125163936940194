@mixin respond($breakpoint) {
  @if $breakpoint == mini-phone {
    @media only screen and (max-width: 25em) {
      @content;
    } //400px
  }

  @if $breakpoint == phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  }

  @if $breakpoint == mini-tab {
    @media only screen and (max-width: 46.87em) {
      @content;
    } //750px
  }

  @if $breakpoint == tab-half {
    @media only screen and (max-width: 53.125em) {
      @content;
    } //850px
  }

  @if $breakpoint == tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  }

  @if $breakpoint == ipad-pro {
    @media only screen and (max-width: 64em) {
      @content;
    } //1024px
  }

  @if $breakpoint == tab-land {
    @media only screen and (max-width: 75em) {
      @content;
    } //1200px
  }

  @if $breakpoint == big-desktop {
    @media only screen and (min-width: 112.5em) {
      @content;
    } //1800px
  }
}
