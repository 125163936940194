@import "../../styles/global.scss";

.background {
  grid-row: 7 / 8;
  grid-column: full-start / full-end;
  background: radial-gradient(#270e2f, #6e5e74 200%);
}

.container {
  margin-top: 5rem;
  margin-bottom: 5rem;
  grid-row: 7/ 8;
  grid-column: center-start / center-end;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);

  &-links {
    grid-column: 1 / 3;
    grid-row: 1 / 2;
    display: grid;
    grid-template-columns: repeat(2, min-content);
    justify-content: space-between;
    column-gap: 4rem;
    align-items: center;

    @include respond(tab-port) {
      grid-column: 1 / 3;
      grid-row: 2 / 3;
      justify-content: center;
      row-gap: 4rem;
    }

    & div {
      display: flex;
      column-gap: 4rem;

      @include respond(tab-port) {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        display: none;
      }
    }

    & img {
      width: 145px;
      height: 90px;

      @include respond(tab-port) {
        justify-self: center;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        margin-top: 5rem;
      }
    }

    & span {
      color: white;
      font-weight: 600;
      font-size: 2rem;
      cursor: pointer;

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &-newsletter {
    display: grid;
    align-content: center;
    row-gap: 2rem;
    justify-content: center;

    grid-column: 1 / 3;
    grid-row: 2 / 3;
    margin-top: 2rem;
    margin-bottom: 4rem;

    @include respond(tab-port) {
      grid-column: 1 / 3;
      grid-row: 1 / 2;
      justify-content: center;
    }

    & h3 {
      text-align: center;
    }

    &__form {
      display: flex;
      column-gap: 2.5rem;

      @include respond(phone) {
        justify-content: center;
      }

      & input,
      & select {
        padding: 1rem;
        width: 30rem;
        max-width: 100%;
        border-radius: 5px;
        border: 1px solid white;
        background-color: rgba($color-primary, $alpha: 0);
        color: white;
        font-size: 1.8rem;

        @include respond(ipad-pro) {
          width: 18rem;
        }

        @include respond(tab-port) {
          width: 30rem;
        }

        @include respond(phone) {
          width: 18rem;
        }
      }

      & input::placeholder {
        font-size: 1.8rem;
        color: white;
      }

      & input:focus {
        outline: none;
      }

      & button {
        background-color: white;
        color: $color-primary;
        border: none;
        padding: 1rem 2rem;
        margin-left: 2rem;
        border-radius: 5px;
        font-size: 1.8rem;
        cursor: pointer;
      }
    }
  }

  &-social {
    grid-column: 1 / 3;
    grid-row: 3 / 4;
    display: flex;
    column-gap: 3rem;
    justify-self: center;
    margin-top: 2rem;
    margin-bottom: 3rem;
    opacity: 0.95;

    .icons {
      width: 50px;
      height: 50px;
    }

    @include respond(tab-port) {
      grid-row: 3 / 4;
      margin-top: 7rem;
    }
  }

  & span {
    grid-column: 1 / 3;
    grid-row: 4 / 5;
    color: white;
    text-align: center;

    @include respond(tab-port) {
      grid-row: 4 / 5;
    }
  }
}
