@import "../../styles/global.scss";

.container {
  grid-column: center-start / center-end;
  grid-row: 6 /7;
  margin-top: 5rem;
  margin-bottom: 10rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, min-content);

  @include respond(tab-port) {
    grid-column: full-start / full-end;
    margin-bottom: 0px;
  }

  &-text {
    background: radial-gradient(#270e2f, #6e5e74 200%);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: 10%;
    padding-right: 10%;

    grid-row: 1 / 2;
    grid-column: 1 / 2;

    @include respond(tab-port) {
      border-radius: 0px;
      padding-top: 8rem;
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }

    display: grid;
    row-gap: 3rem;

    & h2 {
      text-align: center;
      color: white;
      font-size: 4rem;

      @include respond(tab-port) {
        margin-bottom: 3rem;
      }

      @include respond(phone) {
        font-size: 3.5rem;
        margin-bottom: 0.5rem;
      }
    }

    &__box {
      display: flex;
      align-items: center;
      gap: 2rem;

      & img {
        width: 42px;
        height: 42px;

        @include respond(tab-port) {
          width: 40px;
          height: 40px;
        }

        @include respond(phone) {
          width: 35px;
          height: 35px;
        }
      }

      & span {
        font-family: $second-body-font-family;
        font-size: 1.8rem;
        color: rgba($color-white, $alpha: 0.85);
      }
    }
  }

  &-map {
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    @include respond(tab-port) {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
      height: 400px;
    }
  }
}
